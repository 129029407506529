import React, { useId, useState } from 'react'
import {useMediaQuery} from '@react-hook/media-query'
import banner from './assets/banner.png'
import bannerWide from './assets/banner-wide.png'
import logo from './assets/logo.png'
import letterIcon from './assets/letter-icon.svg'
import timelaps1 from './assets/timelaps1.mp4'
import timelaps2 from './assets/timelaps2.mp4'
import entretien from './assets/entretien.jpeg'
import elagage from './assets/elagage.jpeg'
import creation from './assets/creation.jpeg'
import warningIcon from './assets/warning-icon.svg'
import map from './assets/map.jpg'
import arrowBlack from './assets/arrow-right-black.svg'

//import handleViewport from 'react-in-viewport';

import "./App.css"

const MobileVersion = (props) => {
  const id = useId();
  
  const [inputName, setInputName] = useState(props?.value ?? '');
  const [inputTel, setInputTel] = useState(props?.value ?? '');
  const [inputEmail, setInputEmail] = useState(props?.value ?? '');
  const [inputLocation, setInputLocation] = useState(props?.value ?? '');
  const [inputMessage, setInputMessage] = useState(props?.value ?? '');
  const [reponse, setReponse] = useState(props?.value ?? '');

  const handleSubmit = async (e) => {
    e.preventDefault();

    setReponse("Envoi du message...");

    const data = {
      name: inputName,
      tel: inputTel,
      email: inputEmail,
      location: inputLocation, 
      message: inputMessage
    }

    try {
      const response = await fetch('../contact.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        setReponse("Merci! Votre message à bien été envoyé. Nous vous répondrons au plus vite.");
      } else {
        setReponse("Le message n'a pas pu être envoyé.");
        console.log(response);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <img className="banner" src={banner} alt="Jardinage" />
      <div className="accroche">
        <img className="logo" src={logo} alt="Index Jardin" />
        <a href="#contact"><button className="contact-button">
          <img className="letter-icon" src={letterIcon} alt="Lettre" />
          Nous contacter
        </button></a>
        <h1>entretien des espaces verts, élagage et création</h1>
        <p>Index Jardin est une société jeune et dynamique, implantée sur le marché depuis la fin 2023. Particuliers ou Entreprises, nous sommes ravis de vous accompagner dans vos projets, et de répondre à vos attentes dans la réalisation de vos travaux d’espaces verts.</p>
      </div>
      <div className='video1'>
        <div>
          <video src={timelaps1} muted autoPlay loop playsInline></video>
        </div>
      </div>
      <div className="services">
        <h2>Mes services</h2>
        <div className="service-entretien">
          <div className="top" style={{ backgroundImage: `url(${entretien})` }}>
            <h3>Entretien</h3>
          </div>
          <div className="bottom">
            <p>Les différentes prestations qui vous sont proposées pour l’entretien de vos jardins :</p>
            <ul>
              <li>La <b>tonte</b> et le <b>débroussaillage</b> de vos parcelles en herbe</li>
              <li>La <b>taille</b> de haies, d’arbustes et de fruitiers selon les essences et les saisons</li>
              <li>Le <b>ramassage</b> de feuilles</li>
              <li><b>Désherbage</b></li>
            </ul>
          </div>
        </div>
        <div className="service-elagage">
          <div className="top" style={{ backgroundImage: `url(${elagage})` }}>
            <h3>Élagage</h3>
          </div>
          <div className="bottom">
            <p>Nous mettons en avant différentes types de tailles en fonction du bien être végétal et de vos besoins.</p>
            <ul>
              <li><b>Taille de sécurité :</b> Le but étant de protéger les biens et les individus en dessous des arbres ciblés (ex : branches au-dessus de chez les voisins, de parcs de jeux , de toit de maison…)</li>
              <li><b>Taille sanitaire :</b> Cette taille s’applique sur des arbres en difficulté afin d’optimiser leur durabilité. (ex : branches pliées ou cassées à la suite d’une tempête , branches croisées , bois morts ,éviter la propagation de maladies…)</li>
              <li><b>Taille d'entretien :</b> La taille d’entretien sert au maintien ou à la diminution du volume des arbres. (ex : resserrer l’arbre pour plus de lumière et d’espace, répéter une forme et un volume , relever les branches basses..)</li>
              <li><b>Abattage :</b> Dernier recours effectué en cas de danger, de manque d’espace ou de maladies…</li>
            </ul>
          </div>
        </div>
        <div className="service-creation">
          <div className="top" style={{ backgroundImage: `url(${creation})` }}>
            <h3>Création</h3>
          </div>
          <div className="bottom">
            <p>Nous avons également mis un pôle création à votre disposition pour :</p>
            <ul>
              <li>La <b>plantation</b> de vos haies , arbres, arbustes, et fleurs. C’est alors un jeu subtil de volumes et de couleurs qui se mets en place selon vos désirs.</li>
              <li>La <b>création</b> de votre gazon, de la préparation du terrain à la première tonte. Que ce soit du gazon traditionnel ou en plaque, Index Jardin vous accompagne dans les meilleurs conditions.</li>
              <li>Le <b>paillage</b> esthétique et utile à l’enrichissement de vos sols. Idéal pour peaufiner vos extérieurs.</li>
            </ul>
          </div>
        </div>
      </div>
      <div className='video2'>
        <div>
          <video src={timelaps2} muted autoPlay loop playsInline></video>
        </div>
      </div>
      <div className="bandeau">
        <img className="warning-icon" src={warningIcon} alt="Attention" />
        <p>Nous n'utilisons aucun produit phytosanitaire. Tous les déchets verts sont ramassés et recyclés dans des centres agrés.</p>
      </div>
      <div className="rayon">
        <h2>Rayon de déplacement</h2>
        <p>Nos services s’opèrent dans un rayon de 50 km autour de BÛ (28).</p>
        <div className="map-box">
          {/*<img src={map} alt="Rayon de déplacement" />*/}
          <iframe title="Rayon de déplacement" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d42058.432805495686!2d1.4628378283741317!3d48.78853267863629!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6aebbe8bc12f9%3A0x40dc8d705398620!2zMjg0MTAgQsO7!5e0!3m2!1sfr!2sfr!4v1712303880987!5m2!1sfr!2sfr" width="100%" height="100%" style={{border:0}} allowFullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
      <div id="contact" className="contact">
        <h2>Nous contacter / Demande de devis</h2>
        <form onSubmit={handleSubmit}>
          <input id={id} value={inputName} onChange={e => setInputName(e.target.value)} type="text" name="name" required placeholder="Nom*" />
          <input id={id} value={inputTel} onChange={e => setInputTel(e.target.value)} type="tel" name="tel" placeholder="Téléphone" />
          <input id={id} value={inputEmail} onChange={e => setInputEmail(e.target.value)} type="email" name="email" required placeholder="Email*" />
          <input id={id} value={inputLocation} onChange={e => setInputLocation(e.target.value)} type="text" name="location" placeholder="Localisation" />
          <textarea id={id} value={inputMessage} onChange={e => setInputMessage(e.target.value)} name="message" cols="15" rows="5" placeholder="Votre demande..." resize="none"></textarea>
          <p>{reponse}</p>
          <button type="submit"><img src={arrowBlack} alt="Flèche" /> <span>Envoyer</span></button>
        </form>
        <p>Vous pouvez également me joindre</p>
        <span><b>Par mail</b></span>
        <a href="mailto:indexjardin@gmail.com">indexjardin@gmail.com</a>
        <span><b>Par téléphone</b></span>
        <a href="tel:0698567317">06 98 56 73 17</a>
      </div>
      <div className="footer">
        <h3>© Index Jardin 2024</h3>
        <a href="mailto:indexjardin@gmail.com">Mentions légales</a>
        <a href="mailto:indexjardin@gmail.com">Politique de confidentialité</a>
      </div>
    </>
  );
}

const DesktopVersion = (props) => {
  const id = useId();

  const [inputName, setInputName] = useState(props?.value ?? '');
  const [inputTel, setInputTel] = useState(props?.value ?? '');
  const [inputEmail, setInputEmail] = useState(props?.value ?? '');
  const [inputLocation, setInputLocation] = useState(props?.value ?? '');
  const [inputMessage, setInputMessage] = useState(props?.value ?? '');
  const [reponse, setReponse] = useState(props?.value ?? '');

  const handleSubmit = async (e) => {
    e.preventDefault();

    setReponse("Envoi du message...");

    const data = {
      name: inputName,
      tel: inputTel,
      email: inputEmail,
      location: inputLocation, 
      message: inputMessage
    }

    try {
      const response = await fetch('../contact.php', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      });

      if (response.ok) {
        setReponse("Merci! Votre message à bien été envoyé. Nous vous répondrons au plus vite.");
      } else {
        setReponse("Le message n'a pas pu être envoyé.");
        console.log(response);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <>
      <img className="banner" src={bannerWide} alt="Jardinage" />
      <div className="accroche">
        <img className="logo" src={logo} alt="Index Jardin" />
        <a href="#contact"><button className="contact-button">
          <img className="letter-icon" src={letterIcon} alt="Lettre" />
          Nous contacter
        </button></a>
        <h1>entretien des espaces verts, élagage et création</h1>
        <p>Index Jardin est une société jeune et dynamique, implantée sur le marché depuis la fin 2023. Particuliers ou Entreprises, nous sommes ravis de vous accompagner dans vos projets, et de répondre à vos attentes dans la réalisation de vos travaux d’espaces verts.</p>
      </div>
      <div className="videos">
        <div className='left'>
          <video src={timelaps1} muted autoPlay loop playsInline></video>
        </div>
        <div className='right'>
          <video src={timelaps2} muted autoPlay loop playsInline></video>
        </div>
      </div>
      <div className="services">
        <h2>Mes services</h2>
        <div className="service-entretien">
          <div className="blob-box"></div>
          <div className="text-box">
            <h3>ENTRETIEN</h3>
            <p>Les différentes prestations qui vous sont proposées pour l’entretien de vos jardins :</p>
            <ul>
              <li>La <b>tonte</b> et le <b>débroussaillage</b> de vos parcelles en herbe</li>
              <li>La <b>taille</b> de haies, d’arbustes et de fruitiers selon les essences et les saisons</li>
              <li>Le <b>ramassage</b> de feuilles</li>
              <li><b>Désherbage</b></li>
            </ul>
          </div>
        </div>
        <div className="service-elagage">
          <div className="text-box">
            <h3>ÉLAGAGE</h3>
            <p>Nous mettons en avant différentes types de tailles en fonction du bien être végétal et de vos besoins.</p>
            <ul>
              <li><b>Taille de sécurité :</b> Le but étant de protéger les biens et les individus en dessous des arbres ciblés<br></br>(ex : branches au-dessus de chez les voisins, de parcs de jeux , de toit de maison…)</li>
              <li><b>Taille sanitaire :</b> Cette taille s’applique sur des arbres en difficulté afin d’optimiser leur durabilité.<br></br>(ex : branches pliées ou cassées à la suite d’une tempête , branches croisées , bois morts ,éviter la propagation de maladies…)</li>
              <li><b>Taille d’entretien :</b> La taille d’entretien sert au maintien ou à la diminution du volume des arbres.<br></br>(ex : resserrer l’arbre pour plus de lumière et d’espace, répéter une forme et un volume , relever les branches basses..)</li>
              <li><b>Abattage :</b> Dernier recours effectué en cas de danger, de manque d’espace ou de maladies…</li>
            </ul>
          </div>
          <div className="blob-box"></div>
        </div>
        <div className="service-creation">
          <div className="blob-box"></div>
          <div className="text-box">
            <h3>CRÉATION</h3>
            <p>Nous avons également mis un pôle création à votre disposition pour :</p>
            <ul>
              <li>La <b>plantation</b> de vos haies , arbres, arbustes, et fleurs. C’est alors un jeu subtil de volumes et de couleurs qui se mets en place selon vos désirs.</li>
              <li>La <b>création</b> de votre gazon, de la préparation du terrain à la première tonte. Que ce soit du gazon traditionnel ou en plaque, Index Jardin vous accompagne dans les meilleurs conditions.</li>
              <li>Le <b>paillage</b> esthétique et utile à l’enrichissement de vos sols. Idéal pour peaufiner vos extérieurs.</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="bandeau">
        <img className="warning-icon" src={warningIcon} alt="Attention" />
        <p>Nous n'utilisons aucun produit phytosanitaire. Tous les déchets verts sont ramassés et recyclés dans des centres agrés.</p>
      </div>
      <div className="rayon">
        <div className="left-box">
          <h2>Rayon de déplacement</h2>
          <p>Nos services s’opèrent dans un rayon de 50 km autour de BÛ (28).</p>
        </div>
        <div className="map-box">
          {/*<img src={map} alt="Rayon de déplacement" />*/}
          <iframe title="Rayon de déplacement" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d42058.432805495686!2d1.4628378283741317!3d48.78853267863629!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e6aebbe8bc12f9%3A0x40dc8d705398620!2zMjg0MTAgQsO7!5e0!3m2!1sfr!2sfr!4v1712303880987!5m2!1sfr!2sfr" width="100%" height="100%" style={{border:0}} allowFullscreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
      <div className="contact" id="contact">
        <h2>Nous contacter / Demande de devis</h2>
        <form id="contact" onSubmit={handleSubmit}>
          <input id={id} value={inputName} onChange={e => setInputName(e.target.value)} type="text" name="name" required placeholder="Nom*" />
          <input id={id} value={inputTel} onChange={e => setInputTel(e.target.value)} type="tel" name="tel" placeholder="Téléphone" />
          <input id={id} value={inputEmail} onChange={e => setInputEmail(e.target.value)} className="email" type="email" name="email" required placeholder="Email*" />
          <input id={id} value={inputLocation} onChange={e => setInputLocation(e.target.value)} className="location" type="text" name="location" placeholder="Localisation" />
          <textarea id={id} value={inputMessage} onChange={e => setInputMessage(e.target.value)} name="message" cols="15" rows="5" placeholder="Votre demande..."></textarea>
          <input type="submit" id="submit-form" class="hidden" />
        </form>
        <p>{reponse}</p>
        <label for="submit-form"><img src={arrowBlack} alt="Flèche" /> <span>Envoyer</span></label>
        <p>Vous pouvez également me joindre</p>
        <span><b>Par mail</b></span>
        <a href="mailto:indexjardin@gmail.com">indexjardin@gmail.com</a>
        <span><b>Par téléphone</b></span>
        <a href="tel:0698567317">06 98 56 73 17</a>
      </div>
      <div className="footer">
        <h3>© Index Jardin 2024</h3>
        <a href="mailto:indexjardin@gmail.com">Mentions légales</a>
        <a href="mailto:indexjardin@gmail.com">Politique de confidentialité</a>
      </div>
    </>
  );
}

function App() {
  const matches = useMediaQuery('only screen and (min-width: 577px)');

  return (
    !matches ?
        <MobileVersion />
    :
        <DesktopVersion />
  )
}

export default App